
import BaseForm from "@/components/base/form/BaseForm.vue";
import { defineComponent, ref, watch } from "vue";
import * as Yup from "yup";
import { Field } from "vee-validate";
import FormErrorMessage from "@/components/base/form/FormErrorMessage.vue";
import BaseRadio from "@/components/base/form/BaseRadio.vue";
import {BENEFIT_TYPE, PRODUCT_TYPE} from "@/core/config/Constant";
import ProductService from "@/core/services/ProductService";
import { useToast } from "vue-toast-notification";
import Froala from "@/components/base/editor/Froala.vue";

export default defineComponent({
  name: "ProductForm",
  components: { Froala, BaseRadio, FormErrorMessage, BaseForm, Field},
  props: {
    productId: {type: String},
    product: {type: Object}
  },
  emits: ['productSaved'],
  setup(props, {emit}) {
    const model = ref(props.product)
    const submitting = ref(false)
    const validate = Yup.object().shape({
      name: Yup.string().required().label("Name"),
      code: Yup.string().required().label("Code"),
    });
    watch(() => props.product, (cb) => {
      model.value = cb
    })
    const onSubmit = () => {
      submitting.value = true;
      if (props.productId) {
        ProductService.update(props.productId, model.value).then(res => {
          emit('productSaved', res)
        }).finally(() => {
          submitting.value = false;
        })
      } else {
        ProductService.create(model.value).then(res => {
          emit('productSaved', res);
        }).finally(() => {
          submitting.value = false;
        })
      }
    }
    const benefitSubmit = () => {
      console.log("submit benefit")
      submitting.value = true;
      if (props.productId) {
        ProductService.update(props.productId, model.value).then(res => {
          emit('productSaved', res)
        }).finally(() => {
          submitting.value = false;
        })
      } else {
        useToast().error("Invalid product ID", {position: 'top-right'})
      }
    }
    return {
      model,
      submitting,
      validate,
      onSubmit,
      PRODUCT_TYPE,
      benefitSubmit,
      BENEFIT_TYPE,
    }
  }
})

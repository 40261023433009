import { createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "menu-item px-3" }
const _hoisted_2 = { class: "card card-flush" }
const _hoisted_3 = {
  key: 0,
  class: "card-body pt-0"
}
const _hoisted_4 = { class: "menu-item px-3" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "menu-item px-3" }
const _hoisted_7 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QuickAction = _resolveComponent("QuickAction")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_KtDatatable = _resolveComponent("KtDatatable")!
  const _component_Entities = _resolveComponent("Entities")!
  const _component_ProductForm = _resolveComponent("ProductForm")!
  const _component_BaseModal = _resolveComponent("BaseModal")!
  const _directive_is_admin = _resolveDirective("is-admin")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Entities, {
      "enable-filter": false,
      "view-card": false,
      "view-table": true,
      label: "Products",
      items: _ctx.productPage.total,
      "display-view": false
    }, {
      actions: _withCtx(() => [
        _createVNode(_component_QuickAction, null, {
          default: _withCtx(() => [
            _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("a", {
                href: "javascript:void(0);",
                class: "menu-link px-3",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.addProduct && _ctx.addProduct(...args)))
              }, "Add")
            ])), [
              [_directive_is_admin]
            ])
          ]),
          _: 1
        })
      ]),
      table: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.productPage)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_KtDatatable, {
                  "table-data": _ctx.productPage.data,
                  "table-header": _ctx.tableHeader,
                  "enable-items-per-page-dropdown": true,
                  "current-page": _ctx.filter.paginationPage,
                  "rows-per-page": _ctx.filter.paginationSize,
                  onItemsPerPageChange: _ctx.handleUpdateSize,
                  onCurrentChange: _ctx.handleUpdatePage,
                  total: _ctx.productPage.total
                }, {
                  "cell-name": _withCtx(({ row: product }) => [
                    _createVNode(_component_router_link, {
                      to: `/products/` + product.id
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(product.name), 1)
                      ]),
                      _: 2
                    }, 1032, ["to"])
                  ]),
                  "cell-code": _withCtx(({ row: product }) => [
                    _createTextVNode(_toDisplayString(product.code), 1)
                  ]),
                  "cell-type": _withCtx(({ row: product }) => [
                    _createTextVNode(_toDisplayString(product.type.name), 1)
                  ]),
                  "cell-actions": _withCtx(({row: product}) => [
                    _createVNode(_component_QuickAction, null, {
                      default: _withCtx(() => [
                        _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_4, [
                          _createElementVNode("a", {
                            href: "javascript:void(0);",
                            class: "menu-link px-3",
                            "data-bs-toggle": "modal",
                            "data-bs-target": "#productForm",
                            onClick: ($event: any) => (_ctx.editProduct(product))
                          }, "Edit", 8, _hoisted_5)
                        ])), [
                          [_directive_is_admin]
                        ]),
                        _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_6, [
                          _createElementVNode("a", {
                            href: "javascript:void(0);",
                            class: "menu-link px-3 text-danger",
                            onClick: ($event: any) => (_ctx.deleteProduct(product))
                          }, "Delete", 8, _hoisted_7)
                        ])), [
                          [_directive_is_admin]
                        ])
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 1
                }, 8, ["table-data", "table-header", "current-page", "rows-per-page", "onItemsPerPageChange", "onCurrentChange", "total"])
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["items"]),
    _createVNode(_component_BaseModal, {
      name: "Product",
      id: "productForm",
      ref: "productFormRef",
      size: "mw-850px"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ProductForm, {
          "product-id": _ctx.state.productId,
          product: _ctx.state.product,
          onProductSaved: _ctx.onProductSaved
        }, null, 8, ["product-id", "product", "onProductSaved"])
      ]),
      _: 1
    }, 512)
  ], 64))
}

import { computed, defineComponent, reactive, ref } from "vue";
import {LoadFilterObjects, userRoleCheck} from "@/core/composite/composite";
import { Actions } from "@/store/enums/StoreEnums";
import Entities from "@/components/base/common/Entities.vue";
import store from "@/store";
import QuickAction from "@/components/base/action/QuickAction.vue";
import KtDatatable from "@/components/kt-datatable/KTDatatable.vue";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import ProductForm from "@/views/product/ProductForm.vue";
import Swal from "sweetalert2";
import ProductService from "@/core/services/ProductService";

export default defineComponent({
  name: "Products",
  components: {ProductForm, BaseModal, KtDatatable, QuickAction, Entities},
  props: {
    id: {required: true, type: String},
  },
  setup(props) {
    const filterObject = ref({companyId: props.id})
    const state = reactive({
      productId: '',
      product: {
        type: 'GROUP',
        code: '',
        name: '',
        companyId: props.id,
        summary:'',
        policyBenefits: {deductible: 0, coinsurance: 0, maximum: 0}
      }
    })
    const productPage = computed(() => store.state.ProductModule.productPage)
    const tableHeader = ref([
      {
        name: "Name",
        key: "name",
        sortable: true,
      },
      {
        name: "Code",
        key: "code",
        sortable: true,
      },
      {
        name: "Type",
        key: "type",
        sortable: true,
      },
      {
        name: "",
        key: "actions",
        sortable: false,
      }
    ]);
    return {
      ...LoadFilterObjects(Actions.LOAD_PRODUCTS, filterObject.value, ['company']),
      productPage,
      tableHeader,
      state,
      ...userRoleCheck(),
    }
  },
  methods: {
    onProductSaved() {
      const baseModal = this.$refs.productFormRef as typeof BaseModal
      baseModal.hideBaseModal()
      this.paginationLoad()
    },
    addProduct() {
      this.state.productId = '';
      this.state.product.type = 'GROUP'
      this.state.product.code = ''
      this.state.product.name = ''
      this.state.product.companyId = this.id
      this.state.product.summary = ''
      this.state.product.policyBenefits = {deductible: 0, coinsurance: 0, maximum: 0}
      const baseModal = this.$refs.productFormRef as typeof BaseModal
      baseModal.showBaseModal()
    },
    editProduct(product) {
      this.state.productId = product.id;
      this.state.product.type = product.type.code
      this.state.product.code = product.code
      this.state.product.name = product.name
      this.state.product.summary = product.summary
      this.state.product.companyId = product.company.id
      this.state.product.policyBenefits = product.policyBenefits ? product.policyBenefits : {
        deductible: 0,
        coinsurance: 0,
        maximum: 0
      }
    },
    deleteProduct(product) {
      console.log(product);
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          await ProductService.delete(product.id).then(() => {
            this.paginationLoad()
          })
        }
      })
    }
  }
})
